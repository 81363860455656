<template>
  <div>
    <v-data-table :headers="headers" :items="spaces" mobile-breakpoint="350">
      <template v-slot:header="headers">
        <thead v-show="windowWidth >= 350">
          <tr>
            <th class="text-center">
              <v-icon small>mli-boardroom</v-icon>
            </th>
            <th class="text-center">
              <v-icon small>mdi-weather-sunny</v-icon>
            </th>
            <th class="text-center"><v-icon small>mli-dimensions</v-icon></th>
            <th class="text-center"><v-icon small>mli-stairs</v-icon></th>
            <th class="text-center"><v-icon small>mli-hotel-filled</v-icon></th>
            <th class="text-center"><v-icon small>mli-plan</v-icon></th>
            <th class="text-center"><v-icon small>mli-recreational</v-icon></th>
            <th class="text-center"><v-icon small>mli-parking</v-icon></th>
            <th class="text-center"><v-icon small>mdi-human-male</v-icon></th>
            <th class="text-center"><v-icon small>mli-u-shape</v-icon></th>
            <th class="text-center">
              <v-icon small>mli-cocktail-rounds</v-icon>
            </th>
            <th class="text-center">
              <v-icon small>mli-banquet-rounds</v-icon>
            </th>
            <th class="text-center"><v-icon small>mli-theater</v-icon></th>
            <th class="text-center"><v-icon small>mli-classroom</v-icon></th>
            <th class="text-center">
              <v-icon small>mli-boardroom</v-icon>
            </th>
            <th class="text-center"><v-icon small>mli-wifi</v-icon></th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.length="{ item }">
        {{ lengthDimensions(item) }} sqm
      </template>

      <template v-slot:item.category="{ item }">
        <span class="font-weight-bold text-uppercase">{{ item.category }}</span>
      </template>

      <template v-slot:item.is_daylight="{ item }">
        <span v-if="item.is_daylight === 1">
          {{ item.window_length | commaSeparator }}x{{
            item.window_width | commaSeparator
          }}
          m
        </span>
        <span v-else>No Daylight</span>
      </template>

      <template v-slot:item.is_outdoor="{ item }">
        <span v-if="item.is_outdoor === 1">Yes</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.amenities="{ item }">
        <v-chip x-small v-for="amenity in item.amenities" :key="amenity">{{
          amenity
        }}</v-chip>
      </template>

      <template v-slot:item.height="{ item }">
        {{ dimensions(item) }} sqm
      </template>

      <template v-slot:item.parking_slots="{ item }">
        <span v-if="item.has_parking_space === 1">{{
          item.parking_slots | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.standing_max_capacity="{ item }">
        <span v-if="item.is_standing_capacity === 1">{{
          item.standing_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.u_shape_max_capacity="{ item }">
        <span v-if="item.is_banquet_rounds === 1">{{
          item.u_shape_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.u_shape_max_capacity="{ item }">
        <span v-if="item.is_banquet_rounds === 1">{{
          item.u_shape_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.cocktail_rounds_max_capacity="{ item }">
        <span v-if="item.is_cocktail_rounds === 1">{{
          item.cocktail_rounds_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.theater_max_capacity="{ item }">
        <span v-if="item.is_theater === 1">{{
          item.theater_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.classroom_max_capacity="{ item }">
        <span v-if="item.is_classroom === 1">{{
          item.classroom_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.boardroom_max_capacity="{ item }">
        <span v-if="item.is_boardroom === 1">{{
          item.boardroom_max_capacity | commaSeparator
        }}</span>
        <span v-else>No</span>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex">
          <div class="mr-3 justify-content-center align-self-center">
            <v-img
              class="rounded"
              width="36"
              height="36"
              :src="item.images.length > 0 ? item.images[0].url : ''"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 secondary white--text"
                  align="center"
                  justify="center"
                ></v-row>
              </template>
            </v-img>
          </div>
          <div
            class="text-left  justify-content-center align-self-center text-truncate"
          >
            <div class="f-14">{{ item.name }}</div>
            <div
              class="cursor-pointer text--black font-weight-bold f-14"
              @click="viewEventSpaceDetail(item)"
            >
              View details
            </div>
          </div>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-model="eventSpaceDetailModal"
      transition="dialog-top-transition"
      max-width="1230"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="pr-3 headline">
            <div class="d-flex w-100 justify-space-between">
              <h5 class="font-weight-bold">{{ activeSpace.name }}</h5>
              <v-btn icon @click="eventSpaceDetailModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="pt-6 pb-0 black--text">
            <v-row>
              <v-col cols="6">
                <v-carousel
                  height="350px"
                  width="100%"
                  class="rounded-lg carousel-bg"
                  hide-delimiter-background
                  :show-arrows="activeSpace.images.length > 1"
                >
                  <v-carousel-item
                    v-for="(image, i) in activeSpace.images"
                    :key="i"
                    :src="image.url"
                  >
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col cols="6">
                <div class="mb-2"><h6>Description</h6></div>
                <div>
                  <p class="body-1">{{ activeSpace.description }}</p>
                </div>
                <div class="mb-2"><h6>Acoustic Description</h6></div>
                <div>
                  <p class="body-1">{{ activeSpace.acoustic_description }}</p>
                </div>
              </v-col>

              <v-col cols="12">
                <div class="flex-wrap d-flex">
                  <v-card
                    min-width="183px"
                    :elevation="0"
                    class="d-flex pa-3"
                    v-if="
                      activeSpace.category !== '' &&
                      activeSpace.category !== null
                    "
                  >
                    <v-icon color="black" class="mr-3"
                      >mli-event-space-category</v-icon
                    >
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        CATEGORY
                      </div>
                      <div class="subtitle-2">{{ activeSpace.category }}</div>
                    </div>
                  </v-card>

                  <v-card min-width="183px" :elevation="0" class="d-flex pa-3">
                    <v-icon color="black" class="mr-3">mli-coffee-shop</v-icon>
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        OUTDOOR
                      </div>
                      <div class="subtitle-2">
                        {{ activeSpace.is_outdoor === 1 ? "YES" : "NO" }}
                      </div>
                    </div>
                  </v-card>

                  <v-card min-width="183px" :elevation="0" class="d-flex pa-3">
                    <v-icon color="black" class="mr-3">mli-floor</v-icon>
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        Floor Number
                      </div>
                      <div class="subtitle-2">
                        {{ activeSpace.floor_number }}
                      </div>
                    </div>
                  </v-card>

                  <v-card min-width="183px" :elevation="0" class="d-flex pa-3">
                    <v-icon color="black" class="mr-3">mli-floor-type</v-icon>
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        FLOOR TYPE
                      </div>
                      <div class="subtitle-2">{{ activeSpace.floor_type }}</div>
                    </div>
                  </v-card>

                  <v-card
                    min-width="183px"
                    :elevation="0"
                    class="d-flex pa-3"
                    v-if="activeSpace.has_parking_space === 1"
                  >
                    <v-icon color="black" class="mr-3"
                      >mli-parking-space</v-icon
                    >
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        parking space
                      </div>
                      <div class="subtitle-2">
                        {{ activeSpace.parking_slots }}
                      </div>
                    </div>
                  </v-card>

                  <v-card min-width="183px" :elevation="0" class="d-flex pa-3">
                    <v-icon color="black" class="mr-3">mli-area</v-icon>
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        Area
                      </div>
                      <div class="subtitle-2">
                        {{ activeSpace.size | commaSeparator }} sqm
                      </div>
                    </div>
                  </v-card>

                  <v-card min-width="183px" :elevation="0" class="d-flex pa-3">
                    <v-icon color="black" class="mr-3"
                      >mli-arrows-dimension-distance-height</v-icon
                    >
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        Ceiling Height
                      </div>
                      <div class="subtitle-2">
                        {{ activeSpace.height | commaSeparator }}
                      </div>
                    </div>
                  </v-card>

                  <v-card min-width="183px" :elevation="0" class="d-flex pa-3">
                    <v-icon color="black" class="mr-3"
                      >mli-arrows-size-dimension-resize</v-icon
                    >
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        Dimensions
                      </div>
                      <div class="subtitle-2">
                        {{ dimensions(activeSpace) }} sqm
                      </div>
                    </div>
                  </v-card>

                  <v-card
                    min-width="183px"
                    :elevation="0"
                    class="d-flex pa-3"
                    v-if="activeSpace.is_daylight === 1"
                  >
                    <v-icon color="black" class="mr-3">mli-window</v-icon>
                    <div>
                      <div class="event-space-detail-params text-uppercase">
                        Windows
                      </div>
                      <div class="subtitle-2">
                        {{ activeSpace.window_length | commaSeparator }} x
                        {{ activeSpace.window_width | commaSeparator }}
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="6" class="pl-6 pr-16">
                <h6 class="mb-2">Meeting Room Capacities</h6>

                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_boardroom === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-boardroom</v-icon
                    >
                    Boardroom
                  </div>
                  <div>
                    {{ activeSpace.boardroom_max_capacity | commaSeparator }}
                  </div>
                </div>
                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_banquet_rounds === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-banquet-rounds</v-icon
                    >
                    Banquet Rounds
                  </div>
                  <div>
                    {{
                      activeSpace.banquet_rounds_max_capacity | commaSeparator
                    }}
                  </div>
                </div>
                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_cocktail_rounds === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-cocktail-rounds</v-icon
                    >
                    Cocktail Rounds
                  </div>
                  <div>
                    {{
                      activeSpace.cocktail_rounds_max_capacity | commaSeparator
                    }}
                  </div>
                </div>
                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_standing_capacity === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-standing-capacity</v-icon
                    >
                    Standing Capacity
                  </div>
                  <div>
                    {{ activeSpace.standing_max_capacity | commaSeparator }}
                  </div>
                </div>
                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_u_shape === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-u-shape</v-icon
                    >
                    U-Shape
                  </div>
                  <div>
                    {{ activeSpace.u_shape_max_capacity | commaSeparator }}
                  </div>
                </div>
                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_classroom === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-classroom</v-icon
                    >
                    Classroom
                  </div>
                  <div>
                    {{ activeSpace.classroom_max_capacity | commaSeparator }}
                  </div>
                </div>
                <div
                  class="my-3 d-flex justify-space-between"
                  v-if="activeSpace.is_theater === 1"
                >
                  <div class="body-1">
                    <v-icon small color="black" class="mr-3"
                      >mli-theater</v-icon
                    >
                    Theater
                  </div>
                  <div>
                    {{ activeSpace.theater_max_capacity | commaSeparator }}
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <h6 class="mb-2" v-if="activeSpace.amenities.length > 0">
                  Amenities
                </h6>
                <div
                  v-if="activeSpace.amenities.length > 0"
                  v-for="amenity in activeSpace.amenities"
                  class="my-3 d-flex justify-space-between"
                >
                  <div class="body-1">{{ amenity }}</div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["spaces"],
  filters: {
    /*commaSeparator: function (value) {
      return value
        ? value.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : value;
    },*/
    commaSeparator: (value) => {
      if (value && value % 1 != 0) {
        value = value.toFixed(1);
      }

      return value
        ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : value;
    },
  },
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
  },
  data: () => ({
    headers: [
      {
        text: "Meeting Rooms",
        value: "name",
        class:
          "table-header-color black--text  bg-light text-truncate text-center",
      },
      {
        text: "Sq Footage",
        value: "length",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Dimensions",
        value: "height",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Floor Number",
        value: "floor_number",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Floor Type",
        value: "floor_type",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Windows",
        value: "is_daylight",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Outdoor",
        value: "is_outdoor",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Parking Space",
        value: "parking_slots",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Standing Capacity",
        value: "standing_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "U-Shape",
        value: "u_shape_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Cocktail Rounds",
        value: "cocktail_rounds_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Banquet Rounds",
        value: "banquet_rounds_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Theater",
        value: "theater_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Classroom",
        value: "classroom_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Boardroom",
        value: "boardroom_max_capacity",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
      {
        text: "Amenities",
        value: "amenities",
        sortable: false,
        class:
          "table-header-color black--text bg-light text-truncate text-center",
      },
    ],
    eventSpaceDetailModal: false,
    activeSpace: {},
  }),
  methods: {
    viewEventSpaceDetail(space = {}) {
      this.activeSpace = space;
      this.eventSpaceDetailModal = true;
    },
    dimensions(item) {
      if (item.size_type == "measurements") {
        let size = this.numberFormat(item.height * item.width);
        return item.height && item.width
          ? (item.height || 0) + " x " + (item.width || 0) + " m (" + size + ")"
          : size;
      }
      return this.numberFormat(item.size);
    },

    lengthDimensions(item) {
      let size = this.numberFormat(item.length * item.width);
      return item.length && item.width
        ? item.length + " x " + item.width + " m (" + size + ")"
        : this.numberFormat(item.length * item.width);
    },
    numberFormat(value) {
      return value
        ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : value;
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.w-100 {
  width: 100%;
}
.event-space-detail-params {
  color: #b3b3b3;
  font-size: 13px;
  font-weight: bold;
}
.carousel-bg {
  background: #e0e0e0;
}
</style>
<style>
.table-header-color {
  background-color: #f3f3f3;
  font-size: 13px !important;
  font-weight: 400;
}

.table-header-color:first-child {
  background-color: #f3f3f3;
  font-weight: bold;
  text-transform: uppercase;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  background-color: #f9f9f9;
  text-align: center !important;
}
</style>
